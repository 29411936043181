<template>
    <div>
        <Toast />
        <div class="card">
            <h5>Exportación de Saldos</h5>        
        </div>        
        <div class="card">
		<div class="p-m-500">
                <p>Seleccionar el perfil para la exportación de saldos:</p>
                <Divider />
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-fluid p-field p-ml-3">
                        <label for="perfil" class="p-text-bold">Perfiles</label>
                        <Dropdown v-model="id_perfil" :options="perfiles" optionLabel="perfil" optionValue="id_perfil"/>
                    </div>	
                </div>	
                <Divider />
                <div class="p-fluid p-formgrid p-grid">		
                    <div class="p-field p-ml-3">
                        <Button label="Procesar y Descargar" icon="pi pi-download" @click="descargarArchivo()"  icon-pos="right"/>				
                    </div>	
                </div>			
            </div>
        </div>
        <Dialog v-model:visible="loading" :showHeader="true" :modal="true" :closable="false">			
            <div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
                <div class="p-d-flex p-ai-center p-jc-center p-mb-2">
                    <img src="/assets/layout/images/document.gif" width="64" height="64"/>
                </div>		
                <div class="p-d-flex p-ai-center p-jc-center p-text-bold">			
                    <div class="text-lg">Por favor espere un minuto mientras generar el archivo...</div>
                </div>
            </div>
        </Dialog>
    </div>    
</template>
<script>
import { CURRENT_APP_MODE } from '../../service/constants';
import CxcService from '../../service/cxc.service';

export default {
    data() {
        return {
            appMode: CURRENT_APP_MODE,
            loading: false,
            keyUpdate: 0,
            perfiles: [],
            id_perfil: "",
            id_moneda: ""
        }
    },
    mounted() {
		this.loading = true;

		CxcService.getPerfilesExportacion(this.$store.state.auth.currentAppCtx).then(
			data => {
				this.perfiles = data;
				if(this.perfiles.length > 0) {
					this.id_perfil = this.perfiles[0].id_perfil;
					this.id_moneda = this.perfiles[0].id_moneda;
				}

				this.loading = false;
			}
		).catch(
			data => {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}
		)		            
    },    
    methods: {
		descargarArchivo()
		{
			this.loading = true;
			CxcService.getArchivoSaldos(this.$store.state.auth.currentAppCtx, this.id_perfil).then(
				response => {
					//Create a Blob from the PDF Stream
					const file = new Blob(
					[response.data], 
					{type: 'text/csv'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `ArchivoDeSaldos.csv`;
					link.dispatchEvent(new MouseEvent('click'));

					this.loading = false;
				}				
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>